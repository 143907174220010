<template>
  <div style="position: relative">
    <div
      class="
        w-100
        h-100
        d-flex
        align-items-center
        justify-content-center
        module-en-creation
      "
    >
      <span>En cours de création</span>
    </div>
  </div>
</template>

<script src="./propos.js"></script>