export default {
     name: 'Propos',

     components:{

     },

     data(){
          return{

          }
     },

     methods:{

     },

     created(){

     }
}